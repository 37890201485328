import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Link } from 'i18n/Link'
import { Page } from '../components/layout/Page'
import { Container } from '../components/ui-kit/Container'
import { CheckMarkElement } from 'components/ui-kit/CheckMarkElement'
import { breakpoints } from 'components/ui-kit/globalValue'
import ShippedSvg from 'assets/icons/shipped.svg'
import { Title } from 'components/ui-kit/title'
import { SertificatLink } from 'components/ui-kit/SertificatLink'
import { P } from 'components/ui-kit/P'
import { Blockquote } from 'components/ui-kit/Blockquote'
import SlideShow from 'components/ui-kit/sliderMini'
import { ImageBox } from 'components/ui-kit/ImageBox'
import { useTranslations } from 'i18n/useTranslations'
import { MDXRenderer } from 'components/ui-kit/MDXRenderer'

const LineRightWidth = 10

const ProductBox = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        align-items: center;
    }
    > blockquote {
        min-width: 60%;
    }
`

const DescriptionElement = styled(P)`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 20px 0;
    @media (min-width: ${breakpoints.tablet}) {
        padding-left: ${LineRightWidth + 1}%;
    }
    :after {
        position: absolute;
        top: 5px;
        left: 15%;
        width: 70%;
        border-top: 2px solid;
        content: '';
        @media (min-width: ${breakpoints.tablet}) {
            top: 28px;
            left: 0;
            width: ${LineRightWidth}%;
        }
    }
`
const DivS = styled.div`
    width: 100%;
    @media (min-width: ${breakpoints.tablet}) {
        width: 130%;
        padding-right: 20px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        width: 80%;
        padding-right: 20px;
    }
`

const DelyveryBlock = styled.div`
    padding: 30px 0;
    background-color: #c4c4c4;
`
const ShippedSvgStyled = styled(ShippedSvg)`
    flex: 0 0 auto;
    height: 50px;
    width: 50px;
    padding: 0 10px 0 0;
    @media (min-width: ${breakpoints.tablet}) {
        padding: 0 50px 0 0;
        height: 120px;
        width: 120px;
    }
`
const VariantDelyveryBox = styled.div`
    display: flex;
    flex-direction: column;
    & p {
        font-size: 20px;
    }
`
const DelyveryContainer = styled(Container)`
    display: flex;
    justify-content: center;
`
const BottomImgStyled = styled.a`
    position: relative;
    width: 100%;
    @media (min-width: ${breakpoints.tablet}) {
        float: right;
        width: 350px;
    }
    @media (min-width: ${breakpoints.desktop}) {
    }
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
`
const OtherProductWraper = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`
const ProductPageContent = (props) => {
    const translations = useTranslations()
    const {
        pageData,
        productDetails,
        otherProducts,
        productQuality,
    } = props.data

    return (
        <Page>
            <Helmet>
                <title>
                    {pageData.frontmatter.title} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content={pageData.frontmatter.description}
                />
            </Helmet>

            <Container>
                <Title as="h1" view="underlined">
                    {translations.products}
                </Title>
                <Title as="h3">{translations.productsTopTitle}</Title>
                <ProductBox>
                    <DivS>
                        <SlideShow />
                    </DivS>
                    <Blockquote>
                        <MDXRenderer>{pageData.code.body}</MDXRenderer>
                    </Blockquote>
                </ProductBox>
                <MDXRenderer
                    components={{
                        p: DescriptionElement,
                        li: SertificatLink,
                    }}
                >
                    {productDetails.code.body}
                </MDXRenderer>
            </Container>
            <DelyveryBlock>
                <DelyveryContainer>
                    <ShippedSvgStyled />
                    <VariantDelyveryBox>
                        <P>{translations.logisticsTopTitle}</P>
                        <ul>
                            <CheckMarkElement>
                                {translations.railwayTransportation}
                            </CheckMarkElement>
                            <CheckMarkElement>
                                {translations.autotransportations}
                            </CheckMarkElement>
                            <CheckMarkElement>
                                {translations.pickUp}
                            </CheckMarkElement>
                        </ul>
                    </VariantDelyveryBox>
                </DelyveryContainer>
            </DelyveryBlock>
            <Container>
                <Title as="h3">{translations.otherProduct}</Title>

                {otherProducts.edges.map(({ node }) => (
                    <div key={node.id}>
                        <Title as="h4" view="underlined" alignment="left">
                            {node.frontmatter.title}
                        </Title>
                        <OtherProductWraper>
                            <MDXRenderer
                                components={{
                                    li: CheckMarkElement,
                                    a: BottomImgStyled,
                                    h1: SertificatLink,
                                }}
                            >
                                {node.code.body}
                            </MDXRenderer>
                        </OtherProductWraper>
                    </div>
                ))}
                <Title as="h4" view="underlined">
                    {translations.quality}
                </Title>
                <MDXRenderer
                    components={{
                        p: DescriptionElement,
                        li: SertificatLink,
                    }}
                >
                    {productQuality.code.body}
                </MDXRenderer>
            </Container>
            <Link
                to="/product-images/"
                state={{
                    modal: true,
                }}
            >
                <ImageBox images={props.data.images.edges} />
            </Link>
            <Container>
                <Title as="h4">{translations.bottomTitle}</Title>
            </Container>
        </Page>
    )
}

export default ProductPageContent

export const query = graphql`
    query($locale: String! = "uk") {
        images: allFile(filter: { relativeDirectory: { eq: "products" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(
                            maxWidth: 1200
                            maxHeight: 900
                            cropFocus: CENTER
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        pageData: mdx(
            fields: { locale: { eq: $locale }, pageName: { eq: "product" } }
        ) {
            frontmatter {
                title
                description
            }
            code {
                body
            }
        }
        productDetails: mdx(
            fields: {
                locale: { eq: $locale }
                pageName: { eq: "product-details" }
            }
        ) {
            code {
                body
            }
        }
        productQuality: mdx(
            fields: {
                locale: { eq: $locale }
                pageName: { eq: "product-quality" }
            }
        ) {
            code {
                body
            }
        }
        otherProducts: allMdx(
            filter: {
                fields: {
                    locale: { eq: $locale }
                    pageName: { regex: "/^other-product-/" }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                    }
                    code {
                        body
                    }
                }
            }
        }
    }
`
