import React from 'react'
import styled from 'styled-components'
import FileSvg from 'assets/icons/file.svg'
import { breakpoints } from 'components/ui-kit/globalValue'

const Sertificat = styled.li`
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    text-decoration: none;
    color: #222;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 20px;
    }
    & a {
        text-align: left;
        color: #333;
    }
`
const FileSvgStyled = styled(FileSvg)`
    display: inline-block;
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
    padding-right: 5px;
    @media (min-width: ${breakpoints.tablet}) {
        height: 20px;
        width: 20px;
    }
`

export const SertificatLink = (props) => {
    return (
        <Sertificat>
            <FileSvgStyled />
            {props.children}
        </Sertificat>
    )
}
